<template>
  <div>
    <!-- BOC:[header] -->
    <v-row class="mb-3">
      <v-col class="text-right">
        <v-btn
          color="primary"
          large
          :to="{
            name: 'PageModeratorInstitutionSponsorSponsorshipInstitutionAdd',
            params: {
              parentId: parent.id,
              parent: parent,
              grandParentId: grandParent.id,
              grandParent: grandParent,
            },
          }"
        >
          + Add Institution
        </v-btn>
      </v-col>
    </v-row>
    <!-- EOC -->
        <BreadBrowseTable
          role="Moderator"
          :model="model"
          :url="`${$api.servers.sso}/api/v1/en/moderator/sponsor/${grandParent.id}/sponsorship/${parent.id}/institution`"
          :isSearchable="true"
          :parent="parent"
          :modelParent="modelParent"
          :grandParent="grandParent"
          :modelGrandParent="modelGrandParent"
        ></BreadBrowseTable>
        <!-- EOC -->
  </div>
</template>

<script>
//BOC:[model]
import modelGrandParent from "@/models/items/institutionSponsor";
import modelParent from "@/models/items/sponsorSponsorship";
import model from "@/models/items/institutionSponsorship";
//EOC
//BOC:[table]
import BreadBrowseTable from "@/components/Bread/BreadBrowseTable";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //BOC:[table]
    BreadBrowseTable,
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  props: ["role", "parent", "grandParent"],
  data: () => ({
   
      //EOC
    //BOC:[model]
    modelParent: modelParent,
    modelGrandParent: modelGrandParent,
    model: model,
    //EOC
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>