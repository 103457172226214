var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-3"},[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary","large":"","to":{
          name: 'PageModeratorInstitutionSponsorSponsorshipInstitutionAdd',
          params: {
            parentId: _vm.parent.id,
            parent: _vm.parent,
            grandParentId: _vm.grandParent.id,
            grandParent: _vm.grandParent,
          },
        }}},[_vm._v(" + Add Institution ")])],1)],1),_c('BreadBrowseTable',{attrs:{"role":"Moderator","model":_vm.model,"url":((_vm.$api.servers.sso) + "/api/v1/en/moderator/sponsor/" + (_vm.grandParent.id) + "/sponsorship/" + (_vm.parent.id) + "/institution"),"isSearchable":true,"parent":_vm.parent,"modelParent":_vm.modelParent,"grandParent":_vm.grandParent,"modelGrandParent":_vm.modelGrandParent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }